import * as React from "react"
import styled from 'styled-components'
import SEO from '../components/SEO.js'
import Navbar from '../components/Navbar.js'
import Footer from '../components/Footer.js'
import Header from '../components/Header.js'
import MainEquipment from '../components/tecnologia/MainEquipment.js'
import Backdrop from '../images/banners/tecnologia.jpg'


const TechPage = () => {
  return (
    <Container>
      <SEO title="Nuestra Tecnología" description="Nuestra Tecnología"/>
      <Header title="Nuestra Tecnología" subtitle="Equipamiento de última generación para el diagnóstico en todas las áreas de análisis clínico" image={Backdrop}/>
      <MainEquipment/>
      <Footer/>
      <Navbar/>
    </Container>
  )
}

export default TechPage

const Container = styled.div`
  font-family: Century Gothic,CenturyGothic,sans-serif;
  margin: -8px;
  padding-top: 93px;
`
