import * as React from "react"
import styled from 'styled-components'
import cl900i0 from '../../images/tecnologia/cl900i0.jpg'
import bs200_0 from '../../images/tecnologia/bs200_0.jpg'
import bc30s from '../../images/tecnologia/bc30s.jpg'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import VideoPlayer from './VideoPlayer.js'
import CloseIcon from '../../images/icons/whiteClose.png'

const MainEquipment = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Grid direction="row" container>
        <Grid container xs={12} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <Grid xs={6} alignItems="center" justify="center" container>
            <Equipment src={cl900i0}/>
          </Grid>
          <Grid xs={6} alignItems="center" justify="center" item container>
            <div>
              <ResponsiveTitle style={{ textAlign: "center" }}>CL-900i</ResponsiveTitle>
              <ResponsiveSubtitle style={{ fontWeight: "100", textAlign: "center" }}>Sistema de inmunoensayo de quimioluminiscencia</ResponsiveSubtitle>
              <ResponsiveSubtitle style={{ fontWeight: "100", textAlign: "center" }}>Principio de medición basada en plataforma de partículas superparamagnéticas de micrón</ResponsiveSubtitle>
              <div onClick={handleOpen}><ResponsiveSubtitle style={{ textAlign: "center", cursor: "pointer", color: "#000353", textDecoration: "underline" }}>Ver video</ResponsiveSubtitle></div>
            </div>
          </Grid>
        </Grid>
        <ResponsiveGrid container xs={12} md={6}>
          <Grid xs={6} md={8} alignItems="center" justify="center" container>
            <Equipment src={bs200_0}/>
          </Grid>
          <Grid xs={6} md={4} alignItems="center" justify="center" item container>
            <div>
              <ResponsiveTitle style={{ textAlign: "center" }}>BS-200</ResponsiveTitle>
              <ResponsiveSubtitle style={{ fontWeight: "100", textAlign: "center" }}>Autoanalizador químico clínico</ResponsiveSubtitle>
            </div>
          </Grid>
        </ResponsiveGrid>
        <ResponsiveGrid container xs={12} md={6}>
          <Grid xs={6} md={8} alignItems="center" justify="center" container>
            <Equipment src={bc30s}/>
          </Grid>
          <Grid xs={6} md={4} alignItems="center" justify="center" item container>
            <div>
              <ResponsiveTitle style={{ textAlign: "center" }}>BC 30S</ResponsiveTitle>
              <ResponsiveSubtitle style={{ fontWeight: "100", textAlign: "center" }}>Analizador automático para hematología</ResponsiveSubtitle>
            </div>
          </Grid>
        </ResponsiveGrid>
      </Grid>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ResponsiveModal>
          <CloseButton>
            <div onClick={handleClose} style={{ cursor: "pointer", width: "30px", height: "30px", borderRadius: "15px", backgroundColor: "rgba(200,200,200,0.8)", margin: "10px" }}>
              <img src={CloseIcon} width={30} hieght={30}/>
            </div>
          </CloseButton>
          <VideoPlayer/>
        </ResponsiveModal>
      </Modal>
    </Container>
  )
}

export default MainEquipment

const Container = styled.div`
  margin-bottom: 5%;

  @media only screen and (max-width: 850px) {
    margin-top: 5%;
    margin-bottom: 10%;
  }
`

const Equipment = styled.img`
  object-fit: contain;
  width: 90%;
  padding-top: 2%;
  padding-left: 5%;
  padding-right: 5%;

  @media only screen and (max-width: 650px) {
    max-width: 300px
  }
`

const ResponsiveGrid = styled(Grid)`
  margin-top: 20px;
  padding-left: 5%;
  padding-right: 5%;

  @media only screen and (max-width: 650px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const ResponsiveTitle = styled.h1`
  font-size: 24px;

  @media only screen and (max-width: 650px) {
    font-size: 20px;
  }
`

const ResponsiveSubtitle = styled.h3`
  font-size: 17px;

  @media only screen and (max-width: 650px) {
    font-size: 13px;
  }
`

const ResponsiveModal = styled.h3`
  margin-left: 25%;
  width: 50%;
  height: 0;
  margin-top: 80px;

  @media only screen and (max-width: 1000px) {
    margin-left: 10%;
    width: 80%;
  }

  @media only screen and (max-width: 650px) {
      margin-left: 0;
      width: 100%;
  }

  @media only screen and (max-height: 600px) {
      margin-left: 0;
      width: 100%;
      margin-top: 0;
  }
`

const CloseButton = styled.div`
  position: absolute;
  color: white;
  right: 25%;

  @media only screen and (max-width: 1000px) {
    right: 10%;
  }

  @media only screen and (max-width: 650px) {
    right: 0;
  }

  @media only screen and (max-height: 600px) {
    top: 10vh;
    right: 0;
  }
`
