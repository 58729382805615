import * as React from "react"
import styled from 'styled-components'
import cl900i from '../../images/videos/cl900i.mp4'

const VideoPlayer = (videoSrc, title) => {
  return (
    <ResponsiveVideo
      src={cl900i}
      title={title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  )
}

const ResponsiveVideo = styled.iframe`
  width: 100%;
  height: 50vw;
  max-height: 500px;

  @media only screen and (max-height: 600px) {
    height: 80vh;
    margin-top: 10vh;
  }
`

export default VideoPlayer